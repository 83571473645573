<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Region bearbeiten">
      <region-form
        :loading="loading"
        :region="region"
        :form-submitted="saveRegion"
        mode="edit"
      ></region-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionForm from "@/components/forms/RegionForm"
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Region bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      region: {}
    }
  },
  async created() {
    try {
      this.region = await RegionsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveRegion(region) {
      await RegionsRepository.update(region.id, region)
      this.$router.push({ name: "RegionsPage" })
    }
  }
}
</script>
