<template>
  <base-form
    v-slot="slotProps"
    :model="region"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Region"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Bundesland" prop="state_id">
      <el-select
        v-model="slotProps.model.state_id"
        v-loading="loadingStates"
        style="width: 400px;"
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in states"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Bezirk" prop="district_id">
      <el-select
        v-model="slotProps.model.district_id"
        v-loading="loadingDistricts"
        style="width: 400px;"
        clearable
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in districts"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Name der App" prop="app_name">
      <el-input v-model="slotProps.model.app_name"></el-input>
    </el-form-item>

    <el-form-item label="Hostname der App" prop="app_host">
      <el-input v-model="slotProps.model.app_host"></el-input>
    </el-form-item>

    <el-form-item label="Öffentlich sichtbar für Suchmaschinen?">
      <el-switch
        v-model="slotProps.model.app_public"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-card class="box-card">
      <div slot="header">
        <span><b>Postleitzahlen</b></span>
      </div>
      <div style="margin-bottom: 8px;">
        <el-row :gutter="40">
          <el-col :span="8">
            PLZ
          </el-col>
          <el-col :span="8">
            Ortsname
          </el-col>
          <el-col :span="8"> </el-col>
        </el-row>
      </div>
      <div
        v-for="item in zipcodesWithoutDeleted"
        :key="item.id"
        style="margin-bottom: 16px;"
        class="date-item"
      >
        <el-row :gutter="40">
          <el-col :span="8">
            <el-input v-model="item.number"></el-input>
          </el-col>
          <el-col :span="8">
            <el-input v-model="item.name"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              class="remove-date-button"
              type="danger"
              icon="fal fa-trash-alt"
              @click="removeZipcode(item)"
            >
              Entfernen
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-button
        class="btn-add-zipcode"
        icon="fal fa-plus"
        style="margin-bottom: 16px;"
        @click="addZipcode"
      >
        PLZ hinzufügen
      </el-button>
    </el-card>
  </base-form>
</template>

<script>
import Vue from "vue"
import BaseForm from "@/components/forms/BaseForm"
import StatesRepository from "@/repositories/super_admin/states_repository.js"
import DistrictsRepository from "@/repositories/super_admin/districts_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    BaseForm
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    region: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        state_id: [
          {
            required: true,
            message: "Bitte Bundesland auswählen",
            trigger: "change"
          }
        ],
        app_name: [
          {
            required: true,
            message: "Bitte App Namen angeben",
            trigger: "blur"
          }
        ],
        app_host: [
          { required: true, message: "Bitte Host angeben", trigger: "blur" }
        ]
      },
      loadingStates: true,
      states: [],
      loadingDistricts: true,
      districts: []
    }
  },
  computed: {
    zipcodesWithoutDeleted() {
      if (this.region && this.region.zipcodes_attributes) {
        return this.region.zipcodes_attributes.filter(
          zipcode => zipcode._destroy !== true
        )
      } else {
        return []
      }
    }
  },
  created() {
    StatesRepository.getStates()
      .then(states => {
        this.states = states
        this.loadingStates = false
      })
      .catch(error => {
        this.loadingStates = false
        this.handleApiError(error, "Fehler Laden Bundesländer")
      })
    DistrictsRepository.getDistricts()
      .then(districts => {
        this.districts = districts
        this.loadingDistricts = false
      })
      .catch(error => {
        this.loadingDistricts = false
        this.handleApiError(error, "Fehler Laden der Bezirke")
      })
  },
  methods: {
    addZipcode() {
      this.region.zipcodes_attributes.push({
        number: "",
        name: ""
      })
    },
    removeZipcode(zipcode) {
      Vue.set(zipcode, "_destroy", true)
    }
  }
}
</script>
